import axios from 'axios';
import store from '../views/speedReading/store';
import Vue from 'vue';

let isErrorShown = false; // 用于跟踪是否已经显示错误消息
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

const http = axios.create({
    baseURL: '', withCredentials: true, headers: {
        // 'Content-Type': 'application/json;charset=utf-8'
    }
});

http.interceptors.request.use(config => {
    // 获取最新的令牌
    const token = sessionStorage.getItem('token');

    if (token) {
        config.headers.token = token;
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        if (userInfo) {
            config.headers.yhid = userInfo.yhid || '';
        }
    }
    return config;
}, error => {
    return Promise.reject(error);
});
http.interceptors.response.use((response) => {
    if (response.data.resultCode === 200) {
        return response;
    } else if (response.data.resultCode === -1 || response.data.resultCode === 401) {
        window.location.href = '/sw/login';
        return Promise.reject(response);
    } else {
        return Promise.reject(response);
    }
}, (error) => {
    return Promise.reject(error);
});


async function apiAxios(method, url, params, response) {
    try {
        const res = await http({
            method: method,
            url: url,
            data: method === 'POST' || method === 'PUT' ? params : null,
            params: method === 'GET' || method === 'DELETE' ? params : null
        });

        return new Promise((resolve, reject) => {
            if (res.data.resultCode == 200) {
                resolve(res.data);
            } else if (res.data.resultCode == -1 || res.data.resultCode == 401) {
                // 处理未授权或其他情况
                // window.location.href = browserUrl
                // ...
            } else {
                resolve(res.data);
                // 处理其他情况，显示错误消息等
                // this.$message.error(res.data.resultDesc);
            }
        });
    } catch (err) {
        return new Promise((resolve, reject) => {
            reject(err);
        });
    }
}

export default {
    get: function (url, params, response) {
        return apiAxios('GET', url + '?t=' + new Date().getTime(), params, response);
    }, post: function (url, params, response) {
        return apiAxios('POST', url + '?t=' + new Date().getTime(), params, response);
    }, put: function (url, params, response) {
        return apiAxios('PUT', url + '?t=' + new Date().getTime(), params, response);
    }, delete: function (url, params, response) {
        return apiAxios('DELETE', url + '?t=' + new Date().getTime(), params, response);
    }
};

Vue.prototype.$axios = axios;
