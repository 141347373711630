import axios from './index'

// 查询阅读类型
export const readType = params => {
    return axios.post(`api/8920/readType/selectReadType`, params)
}

// 查询阅读等级
export const readLevel = params => {
    return axios.post(`api/8920/readLevel/selectReadLevel`, params)
}

// 查询该等级下的阅读等级明细和区间段
export const readLevelDetails = params => {
    return axios.post(`api/8920/readLevelDetail/selectReadLevelDetail`, params)
}

// 分页查询阅读书籍
export const readBooks = params => {
    return axios.post(`api/8920/readBooks/selectReadBooksPage`, params)
}

// 获取阅读书籍内容
export const readBooksContent = params => {
    return axios.post(`api/8920/readBooks/selectReadBooksDetail`, params)
}

// 获取阅读书籍章
export const readBooksChapter = params => {
    return axios.post(`api/8920/readBooks/selectReadBooksChapter`, params)
}

// 获取阅读书籍章内容
export const readBooksChapterContent = params => {
    return axios.post(`api/8920/readBooks/selectReadBooksChapterDetail`, params)
}

// 阅读能力测试接口
export const readProficiencyTest = params => {
    return axios.post(`api/8920/readTest/saveReadTest`, params)
}